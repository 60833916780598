import React, { useContext, useEffect } from 'react'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import { Briefcase, Flag, Share, Zap } from 'react-feather'
import { useInView } from 'react-intersection-observer'
import useMedia from 'use-media'
import styled from 'styled-components'
import {
  TextCard,
  TextCardHeading,
  TextCardBody,
  TextCardIcon,
} from '../Cards/TextCard'
import {
  ImageCard,
  ImageCardBody,
  ImageCardContent,
  ImageCardHeading,
  ImageCardImage,
  ImageCardTime,
} from '../Cards/ImageCard'
import {
  buildImageObj,
  ComponentContext,
  getBlogUrl,
  imageUrlFor,
  isMobileDevice,
  MetaComponentContext,
  toBlogDate,
  toEuro,
} from '../helpers'
import { PreviewItem, PreviewPlaceholder } from '../Preview/Preview'
import { Link, LinkButton, Typography, PortableText } from '../utils'

const motionShowcase = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
}
const motionIntro = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.2,
      delayChildren: 0.3,
    },
  },
}
const motionItem = {
  initial: { opacity: 0, y: -10 },
  animate: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.07,
    },
  }),
  exit: (custom) => ({
    opacity: 0,
    y: 0,
    transition: {
      delay: custom * 0.07,
    },
  }),
}

const motionTypo = {
  initial: {
    opacity: 0,
    y: -15,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: [0, 0, 0.58, 1],
    },
  },
}

const motionStaggering = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0, 0, 0.58, 1],
      staggerChildren: 0.2,
      // delayChildren: 0.3,
    },
  },
}
const motionTextCard = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
}

const Homepage = ({
  // buildData, buildMetadata,
  posts,
}) => {
  const [contextComponents] = useContext(ComponentContext)
  const [contextMeta] = useContext(MetaComponentContext)
  const isLargeScreen = useMedia({ minWidth: 768 })
  const isSmall = useMedia({ maxWidth: 424 })

  const [advantageRef, advantageInView] = useInView({
    triggerOnce: true,
    threshold: 0.25,
  })
  const [blogRef, blogInView] = useInView({
    triggerOnce: true,
    threshold: 0.25,
  })

  const advantagesControls = useAnimation()
  useEffect(() => {
    if (advantageInView) {
      advantagesControls.start('animate')
    }
  }, [advantagesControls, advantageInView])
  const blogControls = useAnimation()
  useEffect(() => {
    if (blogInView) {
      blogControls.start('animate')
    }
  }, [blogControls, blogInView])

  return (
    <>
      <ColorWrapper>
        {/* <Mask /> */}
        <Hero>
          <Intro initial="initial" animate="animate" variants={motionIntro}>
            <Typography
              variant="h1"
              style={{ alignSelf: 'end' }}
              variants={motionTypo}
            >
              Nur ein Click <br />
              zum neuen PC.
            </Typography>
            <Typography
              color="rgba(255,255,255,0.8)"
              fontWeight="bold"
              variants={motionTypo}
            >
              ghostbuilder ist dein kostenloser PC-Konfigurator,
              {!isSmall && <br />}
              der mit Algorithmen in Echtzeit einen Computer
              {!isSmall && <br />}
              zusammenstellt &amp; die günstigsten Preise findet.
            </Typography>
            <LinkButton
              to="/builder/"
              size="large"
              variants={motionTypo}
              whileTap={{ scale: 0.96 }}
              whileHover={{ scale: 1.04 }}
            >
              Probiere es gleich aus
            </LinkButton>
          </Intro>
          {!isLargeScreen && (
            <Typography
              variant="h2"
              initial={{
                opacity: 0,
                y: -15,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.9,
                  duration: 0.5,
                  ease: [0, 0, 0.58, 1],
                },
              }}
              style={{ justifySelf: 'start', margin: '2rem 0 1rem' }}
            >
              Vorschau
            </Typography>
          )}
          <ShowcaseWrapper
            initial={{
              opacity: 0,
              y: -25,
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                delay: isLargeScreen ? 0.6 : 0.7,
                duration: 0.5,
                ease: [0, 0, 0.58, 1],
              },
            }}
          >
            <Showcase layout transition={{ duration: 0.3 }}>
              <ShowcaseHeader>
                <Typography variant="h3">
                  {isMobileDevice ? 'Build' : 'Preview'}
                </Typography>
                {contextMeta && contextMeta.buildPrice && (
                  <Typography
                    fontWeight="medium"
                    animate
                    transition={{ duration: 1 }}
                  >
                    {toEuro(contextMeta.buildPrice)}
                  </Typography>
                )}
              </ShowcaseHeader>
              <AnimatePresence>
                <motion.div
                  layout
                  initial="initial"
                  animate="animate"
                  variants={motionShowcase}
                  key="PrevierContainer"
                >
                  {contextComponents && !!contextComponents?.length
                    ? contextComponents.map((item, index) => (
                        <PreviewItem
                          {...item}
                          key={`${item.name}${Math.random() * 10}`}
                          custom={index}
                          motionItem={motionItem}
                        />
                      ))
                    : [1, 2, 3, 4, 5, 6, 7].map((item) => (
                        <PreviewPlaceholder
                          key={Math.random() * 100}
                          index={item}
                        />
                      ))}
                </motion.div>
              </AnimatePresence>
            </Showcase>
          </ShowcaseWrapper>
        </Hero>
      </ColorWrapper>
      <Wrapper>
        <Container>
          <Typography variant="h2">Vorteile von ghostbuilder</Typography>
          <Grid
            ref={advantageRef}
            initial="initial"
            animate={advantagesControls}
            variants={motionStaggering}
          >
            <TextCard variants={motionTextCard}>
              <TextCardHeading>
                Unabhängig
                <TextCardIcon>
                  <Flag />
                </TextCardIcon>
              </TextCardHeading>
              <TextCardBody>
                Wir sind unabhängig von Herstellern und Händlern. Dadurch können
                wir stets die preiswerteste PC-Konfiguration empfehlen, ohne
                Einschränkungen bei Marken und Anbietern.
              </TextCardBody>
            </TextCard>
            <TextCard variants={motionTextCard}>
              <TextCardHeading>
                Professionell
                <TextCardIcon>
                  <Briefcase />
                </TextCardIcon>
              </TextCardHeading>
              <TextCardBody>
                Der Builder basiert auf fundiertem Wissen zu Hardware und PCs.
                Unsere Algorithmen werden von Profis geschrieben und von
                PC-Enthusiasten laufend verfeinert.
              </TextCardBody>
            </TextCard>
            <TextCard variants={motionTextCard}>
              <TextCardHeading>
                Echtzeit-Preise
                <TextCardIcon>
                  <Zap />
                </TextCardIcon>
              </TextCardHeading>
              <TextCardBody>
                Unsere Preise basieren auf den Echtzeitdaten unseres
                zuverlässigen Partners Geizhals. Dadurch arbeiten wir immer mit
                den aktuellsten und besten Preisen am Markt.
              </TextCardBody>
            </TextCard>
          </Grid>
        </Container>
      </Wrapper>
      <Wrapper>
        <Container>
          <Typography variant="h2">
            Aktuelles vom ghostbuilder Blog{' '}
            <Link
              aria-label="Link to Ghostbuilder Blog"
              to="/blog"
              style={{ marginLeft: 8, padding: 8 }}
              whileTap={{ scale: 0.9375 }}
              whileHover={{ scale: 1.0625 }}
            >
              <Share />
            </Link>
          </Typography>
          <BlogGrid
            ref={blogRef}
            key="BlogGrid"
            initial="initial"
            animate={blogControls}
            variants={motionStaggering}
          >
            {posts &&
              posts.map((post) => (
                <ImageCard
                  key={post.id}
                  as="a"
                  href={`${getBlogUrl(post.publishedAt, post.slug.current)}`}
                  variants={motionTextCard}
                  whileTap={{ scale: 0.98 }}
                >
                  {post.mainImage && post.mainImage.asset && (
                    <ImageCardImage
                      src={imageUrlFor(buildImageObj(post.mainImage))
                        .width(512)
                        .height(Math.floor((9 / 17.14) * 512))
                        .auto('format')
                        .url()}
                      alt={post.mainImage.alt}
                    />
                  )}
                  <ImageCardContent>
                    <ImageCardHeading>{post.title}</ImageCardHeading>
                    <ImageCardTime datetime={toBlogDate(post.publishedAt)} />
                    {isLargeScreen && post._rawExcerpt && (
                      <ImageCardBody as="div">
                        <PortableText blocks={post._rawExcerpt} />
                      </ImageCardBody>
                    )}
                  </ImageCardContent>
                </ImageCard>
              ))}
          </BlogGrid>
        </Container>
      </Wrapper>
    </>
  )
}

export default Homepage

const Wrapper = styled.section`
  padding: 1rem;
  margin-bottom: 2rem;
  min-height: 50vh;
  height: 100%;
  display: flex;
  align-items: center;
`

const ColorWrapper = styled(Wrapper)`
  background: radial-gradient(
      21.45% 42.43% at 29.93% 43.99%,
      #583fa0 0%,
      rgba(115, 89, 191, 0) 100%
    ),
    linear-gradient(112.97deg, #0e99d4 5.81%, rgba(14, 153, 212, 0) 38.8%),
    radial-gradient(
      29.76% 49.52% at 88.92% 92.72%,
      #ffb659 11.46%,
      rgba(232, 122, 94, 0) 100%
    ),
    linear-gradient(180deg, #4b368b 0%, #e8619c 100%);
  min-height: unset;
  display: block;
  align-items: unset;
`

const Hero = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  @media (min-width: 768px) and (max-width: 959px) {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr 400px;
    column-gap: 1rem;
  }
  @media (min-width: 960px) {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 2fr 3fr;
  }
  justify-content: center;
  justify-items: center;
  align-content: center;
  max-width: 1024px;
  margin: 4rem auto 0;
  min-height: calc(90vh - 2rem);
`

const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`
const Grid = styled(motion.div)`
  margin-top: 1.5rem;
  display: grid;
  justify-content: center;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`

const BlogGrid = styled(Grid)`
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 4rem;
  @media (min-width: 768px) {
    grid-template-columns: 1fr;
    margin-bottom: 6rem;
  }
`

const Intro = styled(motion.div)`
  max-width: 1024px;
  display: grid;
  row-gap: 1.5rem;
  grid-template-rows: repeat(3, auto);
  margin: 3rem 0;
  justify-items: start;
  @media (min-width: 768px) {
    justify-self: start;
    grid-template-rows: auto auto auto;
    align-content: center;
  }
`

const ShowcaseWrapper = styled(motion.div)`
  @media (max-width: 767px) {
    overflow-y: scroll;
    height: 320px;
    justify-self: center;
  }

  justify-self: end;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.3)
  );

  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 16px 16px 50px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
`

const Showcase = styled(motion.div)`
  width: 100%;
  min-height: 400px;
  border-radius: 16px;
  padding: 1rem;
  pointer-events: none;

  @media (max-width: 768px) {
    overflow: auto;
    position: relative;
  }
  @media (min-width: 768px) {
    max-width: 424px;
    border-radius: 32px;
  }
  @media (min-width: 1280px) {
    padding: 1rem 1.5rem;
  }
`

const ShowcaseHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`
